import moment from "../utilities/Moment";
import store from "../store";
import { Moment } from "moment";
import TimeFormatter from "../utilities/TimeFormatter";
import { TimeEntry } from "../types";
import { MomentRange } from "moment-range";

export default class TimeHelper {
  static convertSecondsToHours(secondsCount) {
    return Number((secondsCount / 3600).toFixed(2));
  }

  static getCurrentTimeInSeconds() {
    const now = moment();
    const startOfDay = moment().startOf("day");

    return now.diff(startOfDay, "seconds");
  }

  static getNewTimeEntry(defaultRate: number): TimeEntry {
    return {
      description: "",
      contact_id: "",
      project_id: "",
      rate: defaultRate,
      billable: true,
      active: false,
      fixed_rate: false,
      started_at: moment().utc().toString(),
      ended_at: moment().utc().toString(),
    };
  }

  static getDateRange(startDate: Moment, stopDate: Moment): Moment[] {
    // @ts-ignore
    return Array.from(moment.range(startDate, stopDate).by("day"));
  }

  static getTimeEntryColor(timeEntry?: TimeEntry) {
    if (timeEntry) {
      if (timeEntry.project) {
        return timeEntry.project.color;
      } else if (timeEntry.contact) {
        return timeEntry.contact.color;
      }
    }

    return "#9b9b9b";
  }

  /**
   * Calculates the minutes of the day to compare hh:mm and see which one is bigger
   * @param test
   */
  static minutesOfDay(test: Moment) {
    return test.minutes() + test.hours() * 60;
  }

  static getTimerTitle() {
    const {
      timer: { entry },
    } = store.getState();

    if (entry) {
      const durationlabel = this.getTimerDuration();
      const descriptionLabel = this.getTimerDescription();

      if (durationlabel !== "" && descriptionLabel !== "") {
        return `${durationlabel} - ${descriptionLabel}`;
      }

      return durationlabel;
    }

    return "";
  }

  static setDocumentTimerTitle() {
    const timerTitle = this.getTimerTitle();

    // Only update if valid timer
    if (timerTitle) {
      document.title = timerTitle;
    }
  }

  static getTimerDuration() {
    const {
      authentication: {
        currentUser: {
          workspace: { setting },
        },
      },
      timer: { entry },
    } = store.getState();

    let durationInSeconds = 0;
    if (entry) {
      durationInSeconds = moment
        .duration(moment().diff(moment(entry.started_at)))
        .asSeconds();
    }

    // Only trim when hh mm ss format
    let trim = setting.time_format === "hh mm ss";

    return TimeFormatter.durationFormat(
      durationInSeconds,
      setting.time_format,
      trim
    );
  }

  static getTimerDescription() {
    const {
      timer: { entry },
    } = store.getState();
    if (entry) {
      const { contact, project } = entry;

      if (contact && project) {
        return `${contact.name} › ${project.name}`;
      } else if (contact) {
        return `${contact.name}`;
      } else if (project) {
        return `${project.name}`;
      } else if (entry.description) {
        return entry.description;
      }
    }

    return "";
  }

  static getYearRange(startYear: number) {
    let years = [];
    let currentYear = new Date().getFullYear();
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.reverse();
  }
}
