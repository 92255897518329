import * as React from 'react'
import { BoardListsController, BoardsController, ContactGroupsController, ContactsController, DealStagesController, DealsController, PlaybooksController, ProjectsController, UsersController, WorkTypesController } from '../../controllers'
import Utils from '../../utilities/Utils'
import CreatableSelect from 'react-select/creatable'
import ReactSelectTheme from './ReactSelectTheme'
import { ThemeConfig } from 'react-select/src/theme'
import { Dispatch } from 'redux'
import { showContactGroupModal, showContactModal, showContactTypeModal, showDealModal, showDealStageModal, showProjectModal, showUserWorkspaceSettingModal, showWorkTypeModal, showDocumentTagModal, closeTaskModal } from '../../store/modals/actions'
import { connect } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Styles } from 'react-select/src/styles'
import { OptionsType, ValueType } from 'react-select/src/types'
import UserworkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import deepEqual from 'deep-equal'
import { ContactType, ResourceCreatablePowerSelectType, UserWorkspaceSettingScope } from '../../types'
import { SelectComponents } from 'react-select/src/components'
import ContactSelectComponents from '../Powerselect/ContactSelectComponents'
import ContactEmailIndicatorSelectComponents from '../Powerselect/ContactEmailIndicatorSelectComponents'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import ResourceSelectComponents from '../Powerselect/ResourceSelectComponents'
import DocumentTagsController from '../../controllers/DocumentTagsController'
import DocumentTagSelectComponents from '../Powerselect/DocumentTagSelectComponents'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import RouteHelper from '../../helpers/RouteHelper'
import ERoute from '../../ERoute'

interface IDispatchToProps {
	showContactModal: typeof showContactModal
	showProjectModal: typeof showProjectModal
	showUserWorkspaceSettingModal: typeof showUserWorkspaceSettingModal
	showDealModal: typeof showDealModal
	showDealStageModal: typeof showDealStageModal
	showContactGroupModal: typeof showContactGroupModal
	showContactTypeModal: typeof showContactTypeModal
	showWorkTypeModal: typeof showWorkTypeModal
	showDocumentTagModal: typeof showDocumentTagModal
	closeTaskModal: typeof closeTaskModal
}

type IProps = {
	type: ResourceCreatablePowerSelectType
	value?: string | string[]
	isDisabled?: boolean
	isClearable?: boolean
	// @ts-ignore
	isValidNewOption?: (inputValue: string, value: ValueType<{ label: string; value: string }>, options: OptionsType<{ label: string; value: string }>) => boolean
	params?: { [key: string]: any }
	createParams?: object | any
	onChange?: (value?: string | string[], resource?: any) => void
	theme?: ThemeConfig
	// @ts-ignore
	styles?: Partial<Styles>
	placeholder?: string
	isMulti?: boolean
} & IDispatchToProps & WithTranslation & RouteComponentProps


interface IState {
	valueResources: any[]
	defaultResources: any[]
	searchResources: any[]
	isLoadingValueResource: boolean
	isLoadingDefaultResources: boolean
	isLoadingSearchResources: boolean
	searchValue: string
}

class ResourceCreatablePowerSelectClass extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {
			valueResources: null,
			defaultResources: [],
			searchResources: [],
			isLoadingValueResource: false,
			isLoadingDefaultResources: false,
			isLoadingSearchResources: false,
			searchValue: '',
		}

		this.getResources = this.getResources.bind(this)
		this.getOptions = this.getOptions.bind(this)
		this.getOptionFromResource = this.getOptionFromResource.bind(this)
		this.fetchValueResources = this.fetchValueResources.bind(this)
		this.fetchDefaultResources = this.fetchDefaultResources.bind(this)
		this.fetchDefaultResourcesDebounced = Utils.debounce(this.fetchDefaultResources, 250, false)
		this.fetchSearchResources = this.fetchSearchResources.bind(this)
		this.fetchSearchResourcesDebounced = Utils.debounce(this.fetchSearchResources, 250, false)
		this.onResourceModalSubmit = this.onResourceModalSubmit.bind(this)
		this.onResourceModalDelete = this.onResourceModalDelete.bind(this)
		this.onChange = this.onChange.bind(this)
		this.onInputChange = this.onInputChange.bind(this)
		this.onInputFocus = this.onInputFocus.bind(this)
		this.onCreateOption = this.onCreateOption.bind(this)
		this.onAddOption = this.onAddOption.bind(this)
		this.onEditOption = this.onEditOption.bind(this)
		this.formatCreateLabel = this.formatCreateLabel.bind(this)
		this.isValidNewOption = this.isValidNewOption.bind(this)
		this.getOptionFromResource = this.getOptionFromResource.bind(this)
		this.getComponents = this.getComponents.bind(this)
		this.getUpsertModeEnabled = this.getUpsertModeEnabled.bind(this)
	}

	componentDidMount() {
		if (this.props.value) this.fetchValueResources()
	}

	componentDidUpdate(prevProps: IProps) {
		if (!deepEqual(this.props.value, prevProps.value)) {
			this.fetchValueResources()
		}

		if (!deepEqual(this.props.params, prevProps.params)) {
			this.fetchDefaultResourcesDebounced()
		}
	}

	async fetchValueResources() {
		const { type, value, params } = this.props

		// If value set fetch remote resource
		if (this.props.value) {
			try {
				this.setState({ isLoadingValueResource: true })

				let valueResources = []
				const idValues = Array.isArray(value) ? value : [value]

				switch (type) {
					case 'company':
					case 'company_with_email':
						const { contacts: companies } = await ContactsController.getContacts({ 'id[in]': idValues, type: ContactType.COMPANY })
						valueResources = companies
						break
					case 'person':
					case 'person_with_email':
						const { contacts: people } = await ContactsController.getContacts({ 'id[in]': idValues, type: ContactType.PERSON })
						valueResources = people
						break
					case 'contact':
					case 'contact_with_email':
						const { contacts } = await ContactsController.getContacts({ 'id[in]': idValues })
						valueResources = contacts
						break
					case 'contact_group':
						const { contact_groups } = await ContactGroupsController.getContactGroups({ 'id[in]': idValues })
						valueResources = contact_groups
						break
					case 'project':
						const { projects } = await ProjectsController.getProjects({ 'id[in]': idValues })
						valueResources = projects
						break
					case 'user':
						const { users } = await UsersController.getUsers({ 'users.id[in]': idValues })
						valueResources = users
						break
					case 'deal_stage':
						const { deal_stages } = await DealStagesController.getDealStages({ 'id[in]': idValues })
						valueResources = deal_stages
						break
					case 'deal':
						const { deals } = await DealsController.getDeals({ 'id[in]': idValues })
						valueResources = deals
						break
					case 'work_type':
						const { work_types } = await WorkTypesController.getWorkTypes({ 'id[in]': idValues })
						valueResources = work_types
						break
					case 'board':
						const { boards } = await BoardsController.getBoards({ 'id[in]': idValues })
						valueResources = boards
						break
					case 'board_list':
						if (!params.board_id) {
							valueResources = []
						} else {
							const { board_lists } = await BoardListsController.getBoardLists(params.board_id, { 'id[in]': idValues })
							valueResources = board_lists
						}
						break
					case 'document_tag':
						const { document_tags } = await DocumentTagsController.getDocumentTags({ 'id[in]': idValues })
						valueResources = document_tags
						break
					case 'playbook':
						const { playbooks } = await PlaybooksController.getPlaybooks({ 'id[in]': idValues })
						valueResources = playbooks
						break
					default:
						throw Error(`[fetchValueResource] invalid type ${type} given`)
				}

				this.setState({ valueResources: valueResources })
			} catch (ex) {
				console.error(ex)
				this.setState({ valueResources: null })
			} finally {
				this.setState({ isLoadingValueResource: false })
			}
			// If not set set value resource to null
		} else {
			this.setState({ valueResources: null })
		}
	}

	fetchDefaultResourcesDebounced() {
		this.fetchDefaultResources().catch(console.error)
	}

	async fetchDefaultResources() {
		const { type, params } = this.props

		try {
			this.setState({ isLoadingDefaultResources: true })

			let defaultResources = []

			switch (type) {
				case 'company':
				case 'company_with_email':
					const { contacts: companies } = await ContactsController.getContacts({ ...params, type: ContactType.COMPANY })
					defaultResources = companies
					break
				case 'person':
				case 'person_with_email':
					const { contacts: people } = await ContactsController.getContacts({ ...params, type: ContactType.PERSON })
					defaultResources = people
					break
				case 'contact':
				case 'contact_with_email':
					if (params?.contact_id) {
						// Make a copy
						const customParams = { ...params }

						// Delete contact id
						delete customParams.contact_id

						// Apply filters + id[in]
						const { contacts: selfContacts } = await ContactsController.getContacts({ ...customParams, 'id[in]': params.contact_id })

						defaultResources = [...selfContacts, ...defaultResources]
					}
					const { contacts } = await ContactsController.getContacts({ ...params })

					defaultResources = [...defaultResources, ...contacts]
					break
				case 'contact_group':
					const { contact_groups } = await ContactGroupsController.getContactGroups(params)
					defaultResources = contact_groups
					break
				case 'project':
					const { projects } = await ProjectsController.getProjects(params)
					defaultResources = projects
					break
				case 'user':
					const { users } = await UsersController.getUsers(params)
					defaultResources = users
					break
				case 'deal_stage':
					const { deal_stages } = await DealStagesController.getDealStages(params)
					defaultResources = deal_stages
					break
				case 'deal':
					const { deals } = await DealsController.getDeals(params)
					defaultResources = deals
					break
				case 'work_type':
					const { work_types } = await WorkTypesController.getWorkTypes(params)
					defaultResources = work_types
					break
				case 'board':
					const { boards } = await BoardsController.getBoards(params)
					defaultResources = boards
					break
				case 'board_list':
					if (!params.board_id) {
						defaultResources = []
					} else {
						const { board_lists } = await BoardListsController.getBoardLists(params.board_id, params)
						defaultResources = board_lists
					}

					break
				case 'document_tag':
					const { document_tags } = await DocumentTagsController.getDocumentTags(params)
					defaultResources = document_tags
					break
				case 'playbook':
					const { playbooks } = await PlaybooksController.getPlaybooks(params)
					defaultResources = playbooks
					break
				default:
					throw Error(`[fetchDefaultResources] invalid type ${type} given`)
			}

			this.setState({ defaultResources: defaultResources })
		} catch (ex) {
			console.error(ex)

			this.setState({ defaultResources: null })
		} finally {
			this.setState({ isLoadingDefaultResources: false })
		}
	}

	async fetchSearchResources(inputValue: string) {
		const { type, params } = this.props

		let searchResources = []

		try {
			this.setState({ isLoadingSearchResources: true })

			switch (type) {
				case 'company':
				case 'company_with_email':
					const { contacts: searchNameCompanies } = await ContactsController.getContacts({ ...params, type: ContactType.COMPANY, 'name[cont]': inputValue })
					const { contacts: searchAliasCompanies } = await ContactsController.getContacts({ ...params, type: ContactType.COMPANY, 'alias[cont]': inputValue })

					searchResources = [...searchNameCompanies, ...searchAliasCompanies]
					break
				case 'person':
				case 'person_with_email':
					const { contacts: searchNamePeople } = await ContactsController.getContacts({ ...params, type: ContactType.PERSON, 'name[cont]': inputValue })
					const { contacts: searchAliasPeople } = await ContactsController.getContacts({ ...params, type: ContactType.PERSON, 'alias[cont]': inputValue })

					searchResources = [...searchNamePeople, ...searchAliasPeople]
					break
				case 'contact':
				case 'contact_with_email':
					const { contacts: searchNameContacts } = await ContactsController.getContacts({ ...params, 'name[cont]': inputValue })
					const { contacts: searchAliasContacts } = await ContactsController.getContacts({ ...params, 'alias[cont]': inputValue })

					searchResources = [...searchNameContacts, ...searchAliasContacts]
					break
				case 'contact_group':
					const { contact_groups: searchContactGroups } = await ContactGroupsController.getContactGroups({ ...params, 'name[cont]': inputValue })

					searchResources = [...searchContactGroups]
					break
				case 'project':
					const { projects } = await ProjectsController.getProjects({ ...params, 'name[cont]': inputValue })

					searchResources = projects

					break
				case 'user':
					const { users } = await UsersController.getUsers({ ...params, 'name[cont]': inputValue })

					searchResources = users
					break
				case 'deal_stage':
					const { deal_stages: searchNameDealStages } = await DealStagesController.getDealStages({ ...params, 'name[cont]': inputValue })

					searchResources = [...searchNameDealStages]
					break
				case 'deal':
					const { deals: searchDeals } = await DealsController.getDeals({ ...params, 'name[cont]': inputValue })

					searchResources = [...searchDeals]
					break
				case 'work_type':
					const { work_types: searchWorkTypes } = await WorkTypesController.getWorkTypes({ ...params, 'name[cont]': inputValue })

					searchResources = [...searchWorkTypes]
					break
				case 'board':
					const { boards: searchBoards } = await BoardsController.getBoards({ ...params, 'name[cont]': inputValue })

					searchResources = [...searchBoards]
					break
				case 'board_list':
					if (!params.board_id) {
						searchResources = []
					} else {
						const { board_lists: searchBoardLists } = await BoardListsController.getBoardLists(params.board_id, { ...params, 'name[cont]': inputValue })
						searchResources = [...searchBoardLists]
					}
					break
				case 'document_tag':
					const { document_tags: searchDocumentTags } = await DocumentTagsController.getDocumentTags({ ...params, 'name[cont]': inputValue })

					searchResources = [...searchDocumentTags]
					break
				case 'playbook':
					const { playbooks: searchPlaybooks } = await PlaybooksController.getPlaybooks({ ...params, 'name[cont]': inputValue })

					searchResources = [...searchPlaybooks]
					break
				default:
					throw Error(`[fetchSearchResources] Unsupported resource type ${type} given`)
			}

			this.setState({ searchResources: searchResources })
		} catch (ex) {
			console.error(ex)

			this.setState({ searchResources: null })
		} finally {
			this.setState({ isLoadingSearchResources: false })
		}

	}

	fetchSearchResourcesDebounced(inputValue: string) {
		this.fetchSearchResources(inputValue).catch(console.error)
	}

	getSelectedResource() {
		return this.state.valueResources
	}

	getResources() {
		const { valueResources, defaultResources, searchResources, searchValue } = this.state

		let resources = []

		if (valueResources) {
			resources = [...valueResources, ...resources]
		}

		// Add default resources
		resources = [...resources, ...defaultResources]

		// Add search resources
		resources = [...resources, ...searchResources]

		return resources
			// Remove undefined values
			.filter(Boolean)
			// Get unique values
			.reduce<Array<any>>((uniqueResources, o) => {
				if (!uniqueResources.some(obj => obj.id === o.id)) {
					uniqueResources.push(o);
				}
				return uniqueResources;
			}, [])
	}

	getOptions() {
		const { type } = this.props

		if (type === 'board_list') {
			return this
				.getResources()
				.sort((a, b) => a.position - b.position)
				.map(resource => this.getOptionFromResource(resource))
		} else {
			return this
				.getResources()
				.map(resource => this.getOptionFromResource(resource))
		}
	}

	getSelectedOptions() {
		const { value } = this.props
		const options = this.getOptions()

		if (Array.isArray(value)) {
			return value.map(id => options.find(option => option.value === id))
		} else {
			return options.find(option => option.value === value)
		}
	}

	getOptionFromResource(resource: any) {
		const { type } = this.props

		switch (type) {
			case 'contact':
			case 'contact_with_email':
			case 'company':
			case 'company_with_email':
			case 'person':
			case 'person_with_email':
				return {
					label: resource.alias ? `${resource.name} (${resource.alias})` : resource.name,
					value: resource.id,
					contact: resource
				}
			case 'project':
				return { label: resource.name, value: resource.id }
			case 'user':
				return { label: resource.name ? `${resource.name} (${resource.email})` : resource.email, value: resource.id }
			case 'contact_group':
				return { label: resource.name, value: resource.id }
			case 'deal_stage':
				return { label: resource.name, value: resource.id }
			case 'deal':
				return { label: resource.name, value: resource.id }
			case 'work_type':
				return { label: resource.name, value: resource.id }
			case 'board':
				return { label: resource.name, value: resource.id }
			case 'board_list':
				return { label: resource.name, value: resource.id }
			case 'document_tag':
				return { label: resource.name, value: resource.id, document_tag: resource }
			case 'playbook':
				return { label: resource.name, value: resource.id }
			default:
				throw Error(`[getOption] Unsupported resource type ${type} given`)
		}
	}

	onChange(option) {
		const { isMulti, onChange } = this.props

		if (isMulti) {
			const newValues: string[] = option ? option.map(o => o.value) : null

			const resources = this.getResources()
			let valueResources = null

			if (newValues && newValues.length > 0) {
				valueResources = newValues.map(newValue => resources.find(resource => resource.id === newValue))
			}

			onChange(newValues, valueResources)
		} else {
			const newValue = option ? option.value : null

			const resources = this.getResources()
			let valueResource = null

			if (newValue) valueResource = resources.find(resource => resource.id === newValue)

			onChange(newValue, valueResource)
		}
	}

	onInputChange(inputValue: string) {
		const { searchValue } = this.state
		if (inputValue !== searchValue) {
			this.fetchSearchResourcesDebounced(inputValue)
			this.setState({ searchValue: inputValue })
		}
	}

	onInputFocus() {
		this.fetchDefaultResourcesDebounced()
	}

	async onCreateOption(inputValue: string) {
		const {
			type,
			showContactTypeModal,
			showContactModal,
			showProjectModal,
			showDealModal,
			showContactGroupModal,
			showWorkTypeModal,
			showDocumentTagModal,
			createParams
		} = this.props

		switch (type) {
			case 'person':
			case 'person_with_email':
				const [firstName, ...lastParts] = inputValue.split(' ')

				requestAnimationFrame(() => {
					showContactModal({
						contact: {
							first_name: firstName,
							last_name: lastParts?.join(' ') || '',
							type: ContactType.PERSON,
							...createParams
						},
						onSubmit: this.onResourceModalSubmit
					})
				})
				break
			case 'company':
			case 'company_with_email':
				requestAnimationFrame(() => {
					showContactModal({
						contact: { name: inputValue, type: ContactType.COMPANY, ...createParams },
						onSubmit: this.onResourceModalSubmit
					})
				})
				break
			case 'contact':
			case 'contact_with_email':
				requestAnimationFrame(() => {
					showContactTypeModal({
						onSubmit: (contactType) => {
							const [firstName, ...lastParts] = inputValue.split(' ')

							showContactModal({
								contact: {
									first_name: firstName,
									last_name: lastParts?.join(' ') || '',
									name: inputValue,
									...createParams,
									type: contactType
								},
								onSubmit: this.onResourceModalSubmit
							})
						}
					})
				})
				break
			case 'project':
				requestAnimationFrame(() => {
					showProjectModal({
						project: {
							name: inputValue,
							...createParams,
						},
						contactDisabled: true,
						onSubmit: this.onResourceModalSubmit,
					})
				})
				break
			case 'deal':
				requestAnimationFrame(() => {
					showDealModal({
						deal: { name: inputValue, ...createParams },
						contactDisabled: true,
						projectDisabled: Boolean(createParams?.project_id),
						onSubmit: this.onResourceModalSubmit,
					})
				})
				break
			case 'contact_group':
				requestAnimationFrame(() => {
					showContactGroupModal({
						contactGroup: { name: inputValue, ...createParams },
						onSubmit: this.onResourceModalSubmit,
					})
				})
				break
			case 'work_type':
				requestAnimationFrame(() => {
					showWorkTypeModal({
						workType: { name: inputValue, ...createParams },
						onSubmit: this.onResourceModalSubmit,
					})
				})
				break
			case 'document_tag':
				requestAnimationFrame(() => {
					showDocumentTagModal({
						documentTag: { name: inputValue, ...createParams },
						onSubmit: this.onResourceModalSubmit,
						onDelete: this.onResourceModalDelete
					})
				})
				break
			case 'playbook':
				this.props.history.push(RouteHelper.process(ERoute.PATH_PLAYBOOKS_CREATE))
				this.props.closeTaskModal()
				break
			default:
				throw Error(`[onCreateOption] Unsupported resource type ${type} given`)
		}
	}

	onAddOption(e) {
		e.stopPropagation()
		this.onCreateOption('')
	}

	onEditOption(e, option) {
		e.stopPropagation()

		if (option) {
			const id = option.value

			const {
				type,
				showContactModal,
				showProjectModal,
				showDealModal,
				showDealStageModal,
				showContactGroupModal,
				showWorkTypeModal,
				showDocumentTagModal,
				createParams
			} = this.props

			switch (type) {
				case 'person':
				case 'person_with_email':
					requestAnimationFrame(() => {
						showContactModal({
							contact: { id: id },
							onSubmit: this.onResourceModalSubmit
						})
					})
					break
				case 'company':
				case 'company_with_email':
					requestAnimationFrame(() => {
						showContactModal({
							contact: { id: id },
							onSubmit: this.onResourceModalSubmit
						})
					})
					break
				case 'contact':
				case 'contact_with_email':
					requestAnimationFrame(() => {
						showContactModal({
							contact: { id: id },
							onSubmit: this.onResourceModalSubmit
						})
					})
					break
				case 'project':
					requestAnimationFrame(() => {
						showProjectModal({
							project: { id: id },
							contactDisabled: true,
							onSubmit: this.onResourceModalSubmit,
						})
					})
					break
				case 'deal':
					requestAnimationFrame(() => {
						showDealModal({
							deal: { id: id },
							contactDisabled: true,
							projectDisabled: Boolean(createParams?.project_id),
							onSubmit: this.onResourceModalSubmit,
						})
					})
					break
				case 'deal_stage':
					requestAnimationFrame(() => {
						showDealStageModal({
							dealStage: { id: id },
							onSubmit: this.onResourceModalSubmit,
						})
					})
					break
				case 'contact_group':
					requestAnimationFrame(() => {
						showContactGroupModal({
							contactGroup: { id: id },
							onSubmit: this.onResourceModalSubmit,
						})
					})
					break
				case 'work_type':
					requestAnimationFrame(() => {
						showWorkTypeModal({
							workType: { id: id },
							onSubmit: this.onResourceModalSubmit,
						})
					})
					break
				case 'document_tag':
					requestAnimationFrame(() => {
						showDocumentTagModal({
							documentTag: { id: id },
							onSubmit: this.onResourceModalSubmit,
							onDelete: this.onResourceModalDelete
						})
					})
					break
				case 'playbook':
					this.props.closeTaskModal()
					this.props.history.push(RouteHelper.process(ERoute.PATH_PLAYBOOK, { id: id }))
					break
				default:
					throw Error(`[onEditOption] Unsupported resource type ${type} given`)
			}
		}
	}

	formatCreateLabel(inputValue: string) {
		const { type, t } = this.props

		switch (type) {
			case 'contact':
			case 'contact_with_email':
				return t('ResourceCreatablePowerSelect::Create contact "{{input}}"', { input: inputValue })
			case 'person':
			case 'person_with_email':
				return t('ResourceCreatablePowerSelect::Create person "{{input}}"', { input: inputValue })
			case 'company':
			case 'company_with_email':
				return t('ResourceCreatablePowerSelect::Create company "{{input}}"', { input: inputValue })
			case 'project':
				return t('ResourceCreatablePowerSelect::Create project "{{input}}"', { input: inputValue })
			case 'user':
				return t('ResourceCreatablePowerSelect::Invite team member "{{input}}"', { input: inputValue })
			case 'deal':
				return t('ResourceCreatablePowerSelect::Create deal "{{input}}"', { input: inputValue })
			case 'board':
				return t('ResourceCreatablePowerSelect::Create board "{{input}}"', { input: inputValue })
			case 'board_list':
				return t('ResourceCreatablePowerSelect::Create board list "{{input}}"', { input: inputValue })
			case 'contact_group':
				return t('ResourceCreatablePowerSelect::Create contact group "{{input}}"', { input: inputValue })
			case 'work_type':
				return t('ResourceCreatablePowerSelect::Create work type "{{input}}"', { input: inputValue })
			case 'document_tag':
				return t('ResourceCreatablePowerSelect::Create tag "{{input}}"', { input: inputValue })
			case 'playbook':
				return t('ResourceCreatablePowerSelect::Create playbook "{{input}}"', { input: inputValue })
			default:
				throw Error(`Unsupported resource type ${type} given`)
		}
	}

	onResourceModalSubmit(resource: any) {
		const { type, isMulti } = this.props
		const { valueResources } = this.state

		let updatedValueResources = []
		const valueIndex = valueResources?.findIndex(valueResource => valueResource.id === resource.id)

		if (valueIndex > -1) {
			updatedValueResources = [...valueResources]

			updatedValueResources[valueIndex] = resource
		} else {
			updatedValueResources = valueResources ? [resource, ...valueResources] : [resource]
		}

		this.setState({ valueResources: updatedValueResources }, () => {
			switch (type) {
				case 'company':
				case 'company_with_email':
				case 'person':
				case 'person_with_email':
				case 'contact':
				case 'contact_with_email':
				case 'project':
				case 'user':
				case 'deal_stage':
				case 'deal':
				case 'board':
				case 'board_list':
				case 'contact_group':
				case 'document_tag':
				case 'playbook':
					if (isMulti) {
						this.props.onChange(updatedValueResources.map(resource => resource.id), updatedValueResources)
					} else {
						this.props.onChange(resource.id, resource)
					}
					break
				default:
					throw Error(`[onResourceModalSubmit] Unsupported resource type ${type} given`)
			}
		})
	}

	onResourceModalDelete(resource: any) {
		const { type, isMulti } = this.props
		const { valueResources } = this.state

		let updatedValueResources = valueResources?.filter(valueResource => valueResource.id !== resource.id)

		this.setState({ valueResources: updatedValueResources }, () => {
			switch (type) {
				case 'document_tag':
					if (isMulti) {
						this.props.onChange(updatedValueResources.map(resource => resource.id), updatedValueResources)
					} else {
						this.props.onChange(null, null)
					}
					break
				default:
					throw Error(`[onResourceModalDelete] Unsupported resource type ${type} given`)
			}
		})
	}

	isValidNewOption(inputValue: string, value: any, options: OptionsType<any>) {
		const { type } = this.props

		if (inputValue === '') return false

		switch (type) {
			case 'contact':
			case 'contact_with_email':
			case 'person':
			case 'person_with_email':
			case 'company':
			case 'company_with_email':
				return UserworkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTACT)
			case 'project':
				return UserworkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROJECT)
			case 'user':
				// It doesn't make sense to be able to invite users (also returns no value for user_id since it is an invite)
				return false
			case 'deal':
				return UserworkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DEAL)
			case 'deal_stage':
				return false
			case 'work_type':
				return UserworkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING)
			case 'board':
				return false
			case 'board_list':
				return false
			case 'contact_group':
				return UserworkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING)
			case 'document_tag':
				return UserworkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DOCUMENT)
			case 'playbook':
				return UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PLAYBOOK)
			default:
				throw Error(`[isValidNewOption] Unsupported resource type ${type} given`)
		}
	}

	getComponents(): Partial<SelectComponents<any, any>> {
		const { type } = this.props

		switch (type) {
			case 'contact':
			case 'person':
			case 'company':
				return ContactSelectComponents
			case 'contact_with_email':
			case 'company_with_email':
			case 'person_with_email':
				return ContactEmailIndicatorSelectComponents
			case 'document_tag':
				return DocumentTagSelectComponents
			default:
				return ResourceSelectComponents
		}

		return {}
	}

	getUpsertModeEnabled() {
		const { type } = this.props
		switch (type) {
			case 'board':
			case 'board_list':
				return false
			case 'contact':
			case 'person':
			case 'company':
			case 'contact_with_email':
			case 'company_with_email':
			case 'person_with_email':
				return UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTACT)
			case 'project':
				return UserworkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROJECT)
			case 'deal':
				return UserworkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DEAL)
			case 'deal_stage':
				return UserworkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING)
			case 'contact_group':
			case 'work_type':
				return UserworkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING)
			case 'document_tag':
				return UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DOCUMENT)
			case 'playbook':
				return UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PLAYBOOK)
			case 'user':
				return false
		}

	}

	render() {
		const { isDisabled, isClearable, isValidNewOption, isMulti, placeholder, styles } = this.props
		const { isLoadingValueResource, isLoadingDefaultResources, isLoadingSearchResources } = this.state

		const options = this.getOptions()
		const selectedOptions = this.getSelectedOptions()

		return (
			<CreatableSelect
				options={options}
				value={selectedOptions}
				placeholder={placeholder}
				onChange={this.onChange}
				onCreateOption={this.onCreateOption}
				onInputChange={this.onInputChange}
				formatCreateLabel={this.formatCreateLabel}
				isDisabled={isDisabled}
				isClearable={isClearable}
				isLoading={isLoadingValueResource || isLoadingDefaultResources || isLoadingSearchResources}
				isValidNewOption={isValidNewOption ? isValidNewOption : this.isValidNewOption}
				allowCreateWhileLoading={false}
				theme={ReactSelectTheme}
				styles={styles}
				isMulti={isMulti}
				components={this.getComponents()}
				onFocus={this.onInputFocus}
				onAddClick={this.onAddOption}
				onEditClick={this.onEditOption}
				upsertModeEnabled={this.getUpsertModeEnabled()}
			/>
		)
	}
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
	return {
		showContactModal: (options) => dispatch(showContactModal(options)),
		showProjectModal: (options) => dispatch(showProjectModal(options)),
		showUserWorkspaceSettingModal: (options) => dispatch(showUserWorkspaceSettingModal(options)),
		showDealModal: (options) => dispatch(showDealModal(options)),
		showDealStageModal: (options) => dispatch(showDealStageModal(options)),
		showContactGroupModal: (options) => dispatch(showContactGroupModal(options)),
		showContactTypeModal: (options) => dispatch(showContactTypeModal(options)),
		showWorkTypeModal: (options) => dispatch(showWorkTypeModal(options)),
		showDocumentTagModal: (options) => dispatch(showDocumentTagModal(options)),
		closeTaskModal: () => dispatch(closeTaskModal())
	}
}

export { ResourceCreatablePowerSelectClass }
export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(ResourceCreatablePowerSelectClass)))