import * as React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import * as PopperJS from '@popperjs/core'
import { usePopper } from 'react-popper'
import { PositioningStrategy } from '@popperjs/core'

interface IProps {
  active: boolean
  activator: JSX.Element
  onClose?: () => void
  placement?: PopperJS.Placement
  children: React.ReactNode
  zIndex?: number | string
  strategy?: PositioningStrategy
}

const Container = styled.div`
	position: relative;
  display: inline-block;
`

const Popover = (props: IProps) => {
  const { activator, placement, active, onClose, children, strategy } = props
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: placement,
    strategy: strategy,
  });

  const onOutsideClick = (e) => {
    if (active) {
      if (
        // Additional check for powerselect when click X
        // It gets removed from the DOM which causes the popover to close unintended
        document.contains(e.target) &&
        referenceElement && !referenceElement.contains(e.target) &&
        popperElement && !popperElement.contains(e.target)
      ) {
        if (onClose) onClose()
      }
    }
  }

  // Component did mount
  React.useEffect(() => {
    if (active) {
      if (update) update()
      document.addEventListener('mousedown', onOutsideClick);
    } else {
      document.removeEventListener('mousedown', onOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', onOutsideClick);
    }
  }, [active, update])

  return (
    <>
      {React.cloneElement(activator, { ref: setReferenceElement })}

      {active && <>
        {ReactDOM.createPortal(<div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            visibility: active ? 'visible' : 'hidden',
            pointerEvents: active ? 'all' : 'none',
            zIndex: props.zIndex ? props.zIndex : 999
          }}
          {...attributes.popper}
        >
          {children}
        </div>, document.body)}
      </>}
    </>
  )
}

export default Popover